<template>
    <div class="modal fade" tabindex="-1" role="dialog" ref="master">
	    <div class="modal-dialog" :class="[modalClass]">
            <div class="modal-content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        modalClass: {
            type: String,
            required: false,
        },
    },
    methods: {
        open() {
            const master = this.$refs.master;
            
            $(master).modal('show');
        },
        close() {
            const master = this.$refs.master;
            
            $(master).modal('hide');
        }
    },
    mounted() {
        const master = this.$refs.master;
        
        $(master).on('hidden.bs.modal', (evt) => {
            this.$emit('close');
        })
    }
}
</script>
