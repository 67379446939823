<template>
<div :class="{
    'input-group': hasInputGroup,
}">
    <input type="text" 
        ref="dateInput"
        class="form-control" />
    <span class="input-group-btn" v-if="hasInputGroup">
        <button class="btn btn-default" @click="resetDatePicker">
            <i class="glyphicon glyphicon-remove"></i>
        </button>
    </span>
</div>
</template>

<script>
export default {
    props: {
        settings: {
            type: Object,
            required: true,
        },
        enableReset: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        hasInputGroup() {
            // multiple things in the future might affect this
            return this.enableReset;
        },
    },
    methods: {
        getDate() {
            const dateInput = this.$refs['dateInput'];

            return $(dateInput)
                .data('daterangepicker')
                .startDate
        },
        setDate(date) {
            const dateInput = this.$refs['dateInput'];
            const picker = $(dateInput).data('daterangepicker');

            picker.setStartDate(date);
            picker.setEndDate(date);
        },
        resetDatePicker() {
            const dateInput = this.$refs['dateInput']

            $(dateInput).val('')

            this.$emit('change', null)
        },
        init() {
            const dateInput = this.$refs['dateInput'];
            const { settings } = this;
            const { startDate } = settings;
            const defaultSettings = {
                locale: { 
                    format: 'DD.MM.YYYY',
                    daysOfWeek: _localeDates.dayNamesMin,
                    monthNames: _localeDates.monthNamesShort,
                    firstDay: 1,
                },
            }
        
            $(dateInput).daterangepicker(
                {
                    ...defaultSettings,
                    ...settings,
                    startDate: startDate || moment(),
                }, 
                (startDate, endDate) => {
                    this.$emit('change', { startDate, endDate })
                }
            )

            if (!startDate) {
                this.$nextTick(() => this.resetDatePicker())
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>