import SimpleSearchMixin from './SimpleSearchMixin.js';

export function getType(target) {
    const types = {
        'string': String,
        'number': Number,
        'object': Object,
        'function': Function,
    }

    if (typeof target === 'undefined') {
        return undefined;
    }

    if (target === null) {
        return null;
    }

    if (Array.isArray(target)) {
        return Array;
    }

    return types[typeof target];
}

export function isMoment(anyObj) {
    if (typeof window.moment === 'undefined') {
        return false;
    }

    return window.moment.isMoment(anyObj);
}

export function toDate(anyObj, forceTime = '00:00:00') {
    if (isMoment(anyObj)) {
        return anyObj.toDate();
    }

    const date = new Date(anyObj);

    if (date.toString() === 'Invalid Date') {
        return null;
    }

    if (getType(forceTime) === String) {
        const [ hours, minutes, seconds ] = forceTime.split(':')

        date.setHours(hours)
        date.setMinutes(minutes)
        date.setSeconds(seconds)
        date.setMilliseconds(0)
    }

    return date;
}

export function mixinsFactory(settings = null) {
    if (settings === null) {
        // simple default mixin
        return SimpleSearchMixin;
    }
    
    const defaultSearchArgs = {};
    const filters = {}
    const customFilters = {}
    const filterNames = Object.keys(settings);

    for (let key of filterNames) {
        const filter = settings[key];
        
        switch(filter) {
            case Number:
                defaultSearchArgs[key] = null;
                /**
                 * Try to assure that values are valid numbers before matching
                 * 
                 * @param {String|Number} search 
                 * @param {String|Number} data 
                 * @returns {Boolean}
                 */
                filters[key] = (search, data) => {
                    const searchVal = -(-search);
                    const numVal = -(-data);

                    if (isNaN(numVal) || isNaN(searchVal)) {
                        return false;
                    }

                    return searchVal === numVal;
                }
            break;
            case String:
                defaultSearchArgs[key] = '';
                /**
                 * String filtering function 
                 * 
                 * @param {String} search 
                 * @param {String} data 
                 * @returns {Boolean}
                 */
                filters[key] = (search, data) => {
                    if (getType(data) !== String) return false;
                    
                    const searchArgs = search.trim().toLowerCase().split(',');

                    for (let search of searchArgs) {
                        if (search.trim().length === 0) {
                            continue;
                        }
                        if (data.toLowerCase().includes(search.trim())) {
                            return true;
                        }
                    }

                    return false;
                }
            break;
            case Date:
                defaultSearchArgs[key] = '';
                /**
                 * Trying to compare date strings by creating Date objects
                 * 
                 * @param {String} search 
                 * @param {String} data 
                 * @returns {Boolean}
                 */
                filters[key] = (search, data) => {
                    const searchVal = toDate(search);
                    const dateVal = toDate(data);
                    // invalid search
                    if (searchVal === null) {
                        return true;
                    }
                    // data might be null or not a date so discard the row
                    if (dateVal === null) {
                        return false;
                    }

                    if (searchVal.getTime() != dateVal.getTime()) {
                        return false;
                    }

                    return true;
                }
            break;
            // special case to use window.stringMatches function
            case 'stringMatches':
                defaultSearchArgs[key] = '';
                /**
                 * String filtering using the window.stringMatches function
                 * 
                 * @param {String} search 
                 * @param {String} data 
                 * @returns {Boolean}
                 */
                filters[key] = (search, data) => {
                    if (getType(data) !== String) return false;
                    
                    return window.stringMatches(search, data);
                }
            break;
            case 'daterange':
                /**
                 * @typedef DateRange
                 * @prop {String} start YYYY-MM-DD
                 * @prop {String} end YYYY-MM-DD
                 */
                /**
                 * @type {DateRange}
                 */
                defaultSearchArgs[key] = {
                    start: '',
                    end: '',
                };
                /**
                 * Trying to compare date strings by creating Date objects
                 * 
                 * @param {DateRange} search 
                 * @param {String} data 
                 * @returns {Boolean}
                 */
                filters[key] = (search, data) => {
                    const searchStart = toDate(search.start)
                    const searchEnd = toDate(search.end, '23:59:59')
                    const dateVal = toDate(data, null)
                    // invalid search
                    if (searchStart === null && searchEnd === null) {
                        return true;
                    }
                    // if date is before the start, it is not included
                    if (searchStart !== null && searchStart > dateVal) {
                        return false;
                    }
                    // if date is after the end, it is not included
                    if (searchEnd !== null && searchEnd < dateVal) {
                        return false;
                    }

                    return true;
                }
            break;
            default:
                if (getType(filter) === Function) {
                    customFilters[key] = filter;
                    defaultSearchArgs[key] = '';
                }
        }
    }
    
    return {
        mixins: [ SimpleSearchMixin ],
        data: () => ({
            rows: [],
            searchArgs: defaultSearchArgs,
            currentSortDir: 'asc',
            currentSort: '',
        }),
        computed: {
            filteredRows() {
                let rows = this.rows;

                if (this.preFilteredRows) {
                    rows = this.preFilteredRows;
                }

                const searchArgs = this.searchArgs;
    
                return rows.filter(row => {
                    for (let key of filterNames) {
                        // if search argument or value on this key is empty, just skip
                        if (!searchArgs[key]) continue;
                        if (row[key] === null) return false;
    
                        const searchArg = searchArgs[key]
                        const value = row[key];
    
                        let result = true;
    
                        if (customFilters[key]) {
                            const filterFunc = customFilters[key];
    
                            result = filterFunc(searchArg, row);
                        } else {
                            const filterFunc = filters[key];
    
                            result = filterFunc(searchArg, value);
                        }
    
                        if (result === false) {
                            return false;
                        }
                    }
    
                    return true;
                });
            },
        },
        methods: {
            onSearchChange(evt, name) {
                this.searchArgs[name] = evt.target.value;
            },
        },
    }
}

export function mixinsHelp(mixin = null) {
    // provide specific mixin help when mixin is not null
    console.log(`
The simple default mixin will provide these:

- data
    - rows (for raw data)
    - search (simple string for search, comma separated arguments work)
    - currentSortDir (asc/desc)
    - currentSort (default empty string, use a property name in your data)
- computed
    - filteredRows (this filters the raw data from rows)
    - sortedRows (this sorts the filteredRows data)
- methods
    - setSort(key, forceSort = null) (key = property name in your data, forceSort asc/desc)

All can be overwritten.

The computed property "sortedRows" is what you want to use in a datatable in most cases. Note that you can use this mixin in whatever v-for you have!
`)
}