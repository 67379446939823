const settings = window.wisenetwork || {};
// using angularjs http for now
// otherwise maybe axios...?
const http = angular.injector(["ng"]).get("$http");

export default {
    resetCart() {
        return http({
            method: 'GET',
            url: '/?action=resetcart'
        })
    },
    
    removeFromCart(cartProductId) {
        return http({
            method: 'POST',
            url: settings.REST_URL + '/carts/my/products/' + cartProductId,
            withCredentials: true,
            data: {
                quantity: 0
            }
        })
    },
    
    deleteCartOrder() {
        return http({
            method: 'DELETE',
            url: settings.REST_URL + '/carts/my/order',
            withCredentials: true
        })
    },
    
    createMockReservation(data) {
        const status = 2;
        
        return http({
            method: 'POST',
            url: `${ settings.REST_URL }/reservations/order/${status}`,
            withCredentials: true,
            data
        })
    },

    activateOrder(orderId, hash) {
        return http({
            method: 'GET',
            url: `?action=activateorder&orderId=${ orderId }&hash=${ hash }`,
        })
    },

    disableTime(data) {
        const status = 4;
        
        return http({
            method: 'POST',
            url: `${ settings.REST_URL }/reservations/order/${status}`,
            withCredentials: true,
            data
        })
    },
    
    disableTimeLegacy(data) {
        return http({
            method: 'POST',
            url: '?controller=ajax&reservations=disabletime',
            data
        })
    },
    
    addToCart(data) {
        return http({
            method: 'POST',
            url: settings.REST_URL + '/carts/my/products', //?action=addToCart
            withCredentials: true,
            headers: {
            },
            data
        })
    },
    
    getProductInfo(productId) {
        return http({
            method: 'GET',
            url: settings.REST_URL + '/products/?select=productid,meta,type,price,maximum,visibility,active,manufacturer,images&productid='+productId,
            withCredentials: true,
            //headers: {}
        })
    },
    
    // EcomReservation
    getReservationSettings(productId, date, weekMode = false, adminMode = false) {
        const params = {
            productid: productId,
            date
        };
        
        if (weekMode) {
            params.week = 1;
        }

        if (adminMode) {
            params.mod = 1;
        }
        
        return http({
            method: 'GET',
            url: `/?controller=ajax&reservations=getcalendarsettings&${ $.param(params) }`
        })
    },

    getReservations(productId, date, weekMode = false, type = 1, adminMode = false) {
        const params = {
            productid: productId,
            date
        };
        
        if (weekMode) {
            params.week = 1;
        }

        if (adminMode) {
            params.mod = 1;
        }

        if (type === 5) {
            params.golf = 1;
        }
        
        return http({
            method: 'GET',
            url: `${ settings.REST_URL }/reservations/?${ $.param(params) }`,
            withCredentials: true,
        })
    },
    
    // EcomReservation
    /**
     * 
     * @param {Array} resources - array of ids
     * @param {String} dateTimeStart - format 'YYYY-MM-DD HH:mm'
     * @param {String} dateTimeEnd - format 'YYYY-MM-DD HH:mm'
     * @returns {Promise}
     */
    getResourceReservations(resources, dateTimeStart, dateTimeEnd) {
        const params = $.param({
            resources,
            dateTimeStart,
            dateTimeEnd
        });
        
        return http({
            method: 'GET',
            url: `/?controller=ajax&reservations=getresourcereservations&${ params }`
        })
    },
    
    getMyCart() {
        return http({
            method: 'GET',
            url: settings.REST_URL + '/carts/my/products',
            withCredentials: true,
            headers: {
            }
        })
    },
    
    deactivateReservationTime(reservationTimeId) {
        const data = {
            reservationtimeid: reservationTimeId
        };

        return http({
            method: 'GET',
            url: `?controller=ajax&reservations=deactivatereservationtime&${ $.param(data) }`
        })
    },
    
    editReservationTime(data) {
        return http({
            method: 'POST',
            url: '?controller=ajax&reservations=edittime',
            data
        })
    },

    getUserGolfReservations(params = {}) {
        const paramString = Object.keys(params).length > 0 ? '&'+$.param(params) : '';

        return http({
            method: 'GET',
            url: `?controller=ajax&reservations=getusergolfreservations${ paramString }`,
        })
    },

    getUserReservations(params = {}) {
        const paramString = Object.keys(params).length > 0 ? '&'+$.param(params) : '';

        return http({
            method: 'GET',
            url: `?controller=ajax&reservations=getuserreservations${ paramString }`,
        })
    },

    confirmTeeTime(reservationTimeId) {
        return http({
            method: 'GET',
            url: '?controller=ajax&reservations=confirmgolfteetime&reservationtimeid='+reservationTimeId
        })
    },

    // when an admin accidentally confirms a tee time, they want to cancel that action, not cancel the tee time
    cancelConfirmedTeeTime(reservationTimeId) {
        return http({
            method: 'GET',
            url: '?controller=ajax&reservations=confirmgolfteetime&cancel=1&reservationtimeid='+reservationTimeId
        })
    },

    setReservationSaleMode(data) {
        return http({
            method: 'POST',
            url: '?controller=ajax&reservations=setreservationsalemode',
            data
        })
    },

    pushResourceComment(data) {
        return http({
            method: 'POST',
            url: '?controller=ajax&reservations=pushresourcecomment',
            data
        })
    },

    testProductOwnership(productId, rest = true) {
        let url = `?controller=ajax&reservations=testownership&productid=${ productId }`;

        if (rest) {
            url = `${ settings.REST_URL }/reservations/productownership/?productid=${ productId }`;
        }
        return http({
            method: 'GET',
            url,
            withCredentials: true,
        })
    },

    getShareOwners() {
        return http({
            method: 'GET',
            url: '?controller=ajax&shareowners=1',
        })
    },
}